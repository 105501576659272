.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.header {
  background-color: #fff;
  font-size: calc(10px + 2vmin);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #1d594e;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.link {
  color: #277365;
}

.footer {
  margin-top: auto;
}
